import './FAQ.css'
import {ethers} from 'ethers';
import AnimatedPage from '../../utils/AnimatedPage';
import { Button, Spinner } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect, useRef } from 'react'; 
import VeNFTianAbi from "../../../VeNFTian.json";
import { MDBBtn } from "mdbreact";
import Snackbar from "./../../utils/snackbar";
import Footer from "../Footer/Footer"
import AOS from 'aos';
import Accordion from "./Accordian.js";
import 'aos/dist/aos.css';


AOS.init();

const SnackbarType = {
  success: "success",
  fail: "fail",
};

const questionsAnswers = [
    {
      question: "What is the VeNFTian Poker Club?",
      answer:
        "VeNFTian Poker Club is the worlds first 100% freeroll poker club and we're bringing communities together for an unrivaled Web3 poker experience. Utilising the security and transparency of the latest smart contracts we have revolutionised the registration and reward system of tournament poker.",
      answer2:
        "Our Season Pass allows you to play for over $500,000 worth of prizes in our first 8-week season. With the average tournament payout over $20,000, we have the highest freeroll payouts in all of crypto."
    },
    {
      question: "How can I participate in VeNFTian Tournaments?",
      answer:
        "3 Options:",
      answer2: "1) Be a member of a participating sponsor community (1 weekly tournament)",
      answer3: "2) Hold a VIP Season Pass (3 weekly tournaments)",
      answer4: "3) Hold a Premium Season Pass (3 weekly tournaments)",
      answer5: "Both VIP Season Pass holders and Premium Season Pass holders have access to all 4 weekly tournaments for the duration of the season."
    },
    {
      question: "Can I purchase a VIP Season Pass NFT with my credit card?",
      answer: `Yes! Both our VIP Season Pass and Premium Season Pass can be purchased with credit card and multiple cryptocurrencies. We have partnered with the highly reputable merchant called Crossmint to accept Mastercard, Visa, Apple Pay, Google Pay, Solana, Ethereum, and more!`,

    },
    {
      question: "How long are the seasons and how many tournaments?",
      answer: `• Pre-Season will last for 2 weeks and consist of 2 tournaments.`,
      answer2: `• Standard Seasons will last for 8 weeks and each consist of 32 tournaments. Pre-Season will last for 2 weeks and consist of 2 tournaments.`,
    },
    {
      question: "Will I ever have to pay an entry fee?",
      answer: `No, after becoming a member of VeNFTian Poker Club no further purchases will ever be necessary for the duration of the season. We are the worlds first 100% freeroll poker club meaning that all our tournaments are free to enter. If you have a VIP Season Pass or Premium Season Pass you have free entry to all 32 tournaments of the season.`,
    },
    {
        question: "What do I get as a VeNFTian VIP Season Pass holder?",
        answer: "Our VIP Season Pass holders will have the opportunity to play for: \n",
        answer2: "💰 Over $500,000 USD worth of USDC cryptocurrency given out over 8 weeks",
        answer3:"💰 100% of our royalties that are deposited straight back into tournaments",
        answer4:"💰 Trips to play at prestigious world poker events,",
        answer5:"💰 Various NFT and IRL prizes to be named at a later date",
        answer6:"Our Premium Season Pass Holders will have all of the above with the addition of access to 2 additional 8 week seasons, this a total of 6 months worth of freeroll tournaments."
        
    },
    {
        question: "What is the weekly format of tournaments?",
        answer: `• Each week there will be 4 tournaments, 3 of which will be exclusively for our VIP Season Pass holders and 1 will be open to the members of our participating sponsor communities.`,
        answer2: "• See the 'Prizes' tab for the tournament payout information.",
        answer3: "• See the 'Poker' tab for the tournament event dates / times."
    },
    {
        question: "Are there benefits to holding multiple season passes?",
        answer: `Yes! Watch announcements closely for details on the many BONUSES for holders of multiple passes.`,
    },
    {
        question: "Are all tournaments Texas Hold 'em?",
        answer: `Yes, all tournaments are currently Texas Hold 'em.`,
    },
    {
        question: "How does the registration process work?",
        answer: `• We have built an incredibly simple yet efficient registration process that is fully on-chain.`,
        answer2: "• Once you mint or purchase your NFT all you need to do is go to 'Poker' taband register your NFT, after that you have access to all 32 freeroll tournaments of the season and just need to show up and play!",
        answer3: "• You can join tournaments up to 30 minutes late."
    },
    {
        question: "Can I buy-back into a tournament after losing my chips?",
        answer: `No, to comply with legislation our tournaments are 100% freeroll and the VeNFTian Poker Club cannot accept buy-ins or re-buys.`,
    },
    {
        question: "How do I claim my winnings?",
        answer: `• Firstly, take care in that we always carefully audit all winners`,
        answer2: `• Once a week we will upload the wallets of all winners directly to the contract and you will be able to withdraw your winnings at your leisure from the 'Prizes' tab.`,
        answer3: `• All prizes listed are the estimated retail value and paid in USDC at time of redemption. USDC is a stablecoin cryptocurrency`,
    },
    {
        question: "Will there be future seasons?",
        answer: `Yes, we are already hard at work expanding on our 2nd and 3rd seasons.`,
    }
    ,
    {
        question: "What happens to my VIP Season Pass after the season ends?",
        answer: `Continuing to own a VIP Season Pass from a previous season will provide you with:`,
        answer2:`• Whitelist/Presale to the next season`,
        answer3:`• Access to exclusive tournaments`,
        answer4: "• Bonus buy-backs in future tournaments",
        answer5:`• Access to exclusive real world events hosted by the VeNFTian Poker Club + More`,
    }
    ,
    {
        question: "Can I play on mobile?",
        answer: `Yes.`,
    }
    ,
    {
        question: "Why did you choose ClubGG?",
        answer: `ClubGG allows our members to participate from all over the world with no geo-restrictions. We will continue to use ClubGG until we have developed our own Web3 VeNFTian poker application.`,
    }
  ];
  

const FAQ = () => {

    const [snackBarType, SetSnackBarType] = useState(SnackbarType.fail)
    const [snackBarMessage, SetSnackBarMessage] = useState("")
    const snackbarRef = useRef(null);

    const pokerTable = "https://venftian.s3.eu-west-2.amazonaws.com/FAQBK.jpeg"

    return(
        <div className='container2'>    
            <div className='mint-background'>
                <AnimatedPage>
                <div className='image-header'>
                    <img src={pokerTable}/>
                </div>
                <div className='mint-background-inner'>
                    <div className='page-contents'>
                        <Snackbar
                            ref={snackbarRef}
                            message={snackBarMessage}
                            type={snackBarType}
                        />
                        <div className='title-faq'> Frequently Asked Questions
                        </div>
                        <div className='title-faq-mobile'>FAQ
                        </div>
                        <div className='AccordionHolder'>
                            <Accordion questionsAnswers={questionsAnswers} />
                        </div>
                        {/* <div className='page-sections'>
                            <div className='left'>
                                <div data-aos="fade-right" className='FAQ-Text'>
                                    <h1>What is the VeNFTian Poker Club?</h1>
                                    <p>
                                        The <em>VeNFTian Poker Club</em> seeks to create a unique poker experience bridging 
                                        the gap between traditional players and a new generation of web3 enthusiasts. 
                                    </p>
                                    <p>
                                        Utilising the security and transparency of the latest smart contracts we have revolutionised the registration 
                                        and reward system of tournament poker.
                                    </p>
                                </div>
                                <div data-aos="fade-right" className='FAQ-Text'>
                                    <h1>How long are the seasons and how many tournaments?</h1>
                                    <p>
                                        Each season will last for 8 weeks and consist of 32 tournaments.
                                    </p>
                                </div>
                                <div data-aos="fade-right" className='FAQ-Text'>
                                    <h1>What is the weekly format of tournaments?</h1>
                                    <p>
                                        Each week there will be 4 tournaments, 3 of which will be exclusively for our <em>VIP Season Pass holders</em> and 1 will 
                                        be open to the members of our participating sponsor communities.
                                    </p>
                                    <p>
                                    <font>🏆 Weekly Freeroll Tournament #1</font><br/>
                                            • Requirements: VIP Season Pass Holder<br/>
                                            • Top 100 Placings Paid
                                            0.2 Ethereum Each! (20 Eth total)
                                    </p>
                                    <p>
                                    <font>🏆 Weekly Freeroll Tournament #2</font><br/>
                                        • Requirements: VIP Season Pass Holder<br/>
                                        • Top 100 Placings Paid<br/>
                                        1st - 1.25 Ethereum<br/>
                                        2nd - 0.75 Ethereum<br/>
                                        3rd - 0.50 Ethereum<br/>
                                        4th > 100th - 0.1 Ethereum
                                    </p>
                                    <p>
                                        <font>🏆 Weekly Freeroll Tournament #3</font><br/>
                                        • Requirement:- VIP Season Pass Holder<br/>
                                        • Top 20 Placings Paid<br/>
                                        1st - 3.31 Ethereum<br/>
                                        2nd - 1.96 Ethereum<br/>
                                        3rd - 1.23 Ethereum<br/>
                                        4th > 20th - Gradually lowers
                                    </p>
                                    <p>
                                    <font>🏆 Weekly Freeroll Tournament #4</font><br/>
                                        • Requirements: VIP Season Pass Holder / Collab Partner Member<br/>
                                        • Community tournament sponsored by dozens of NFT communities<br/>
                                        • Prizes will consist of NFT's, whitelist, and other prizes provided by our sponsors.                                        
                                    </p>
                                </div>
                                <div data-aos="fade-right" className='FAQ-Text'>
                                    <h1>Are there benefits to holding multiple season passes?</h1>
                                    <p>
                                        Yes! Every single week we will host an <em>exclusive raffle for holders</em> of multiple passes, 
                                        the weekly prize for one lucky winner is 1 Ethereum.
                                    </p>
                                </div>
                                <div data-aos="fade-right" className='FAQ-Text'>
                                    <h1>Will there be future seasons?</h1>
                                    <p>
                                        Yes, many!
                                    </p>
                                </div>
                                <div data-aos="fade-right" className='FAQ-Text'>
                                    <h1>Can I play on mobile?</h1>
                                    <p>
                                        Yes.
                                    </p>
                                </div>
                            </div>
                            <div data-aos="fade-left" className='right'>
                                <div className='FAQ-Text'>
                                        <h1>How can I participate in VeNFTian Tournaments?</h1>
                                        <p>
                                                • Be a member of a participating sponsor community (1 tournament per week)<br/>
                                                • Be a <em>VIP Season Pass holder</em> (3 tournaments per week)
                                        </p>
                                </div>
                                <div data-aos="fade-left" className='FAQ-Text'>
                                    <h1>What do I get with my VeNFTian VIP Season Pass?</h1>
                                    <p>
                                        Our VIP Season Pass holders will have the opportunity to play for:<br/><p></p>
                                        💰 Over <em>$500,000 USD</em> worth of Ethereum and prizes given out over 8 weeks<br/>
                                        💰 100% of secondary sales royalties that will be deposited back into tournaments<br/>
                                        💰 Trips to play at prestigious world poker events
                                    </p>
                                </div>
                                <div data-aos="fade-left" className='FAQ-Text'>
                                        <h1>Are all tournaments Texas Hold 'em?</h1>
                                        <p>
                                            Yes, all 32 tournmanets of the 8 week season will be Texas Hold 'em.
                                        </p>
                                </div>
                                <div data-aos="fade-left" className='FAQ-Text'>
                                    <h1>What do I get with my VeNFTian VIP Season Pass?</h1>
                                    <p>
                                        We have built an incredibly simple yet efficient registration process that is fully<em> on-chain. </em> <br/>
                                        Once you mint or purchase your NFT all you need to do is go to 'Poker' section of this site and register your NFT, 
                                        after that you have <em>access to all 32 freeroll tournaments</em> of the season and just need to show up and play! 
                                        You can join tournaments up to 30 minutes late.
                                    </p>
                                </div>                                
                                <div data-aos="fade-left" className='FAQ-Text'>
                                    <h1>Can I buy-back into a tournament after losing my chips?</h1>
                                    <p>
                                        Currently no, each player can enter a tournament only once. However, we are looking at introducing buy-backs into future seasons.
                                    </p>
                                </div>
                                <div data-aos="fade-left" className='FAQ-Text'>
                                    <h1>What happens to my VIP Season Pass after the season ends?</h1>
                                    <p>
                                        Continuing to own a <em>VIP Season Pass</em> from a previous season will provide you with:<br/>
                                        • Whitelist/Presale to the next season<br/>
                                        • Bonus buy-backs in future tournaments<br/>
                                        • Access to exclusive tournaments<br/>
                                        • Access to exclusive real world events hosted by the VeNFTian Poker Club<br/>
                                        + More
                                    </p>
                                </div>
                                <div data-aos="fade-left" className='FAQ-Text'>
                                    <h1>Why did you choose ClubGG?</h1>
                                    <p>
                                        ClubGG allows our members to participate from all over the world with no geo-restrictions. 
                                        We will continue to use ClubGG until we have developed <em>our own Web3 VeNFTian poker application.</em>
                                    </p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                </AnimatedPage>
            </div>
            <Footer/>
        </div>
    
    )
}

export default FAQ;