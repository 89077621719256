import './App.css';
import './components/pages/Navbar/Navbar.css'
import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Navbar from "./components/pages/Navbar/Navbar"
import AnimatedRoutes from './components/utils/AnimatedRoutes';
// import { ToastProvider } from 'react-toast-notifications';
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Footer from './components/pages/Footer/Footer';

function App() {
  // const location = useLocation();

  return (
    <Router>
      <Navbar />
      <AnimatedRoutes/>
      {/* <Footer/> */}
    </Router>
  );
}

export default App;
