import { useEffect, useState, useRef } from "react";
import {ethers} from 'ethers';
import React, { Component } from 'react';
import "./Minting.css"
import ReactiveButton from 'reactive-button';
import { confirm } from "react-confirm-box";
import { Button, Spinner } from 'react-bootstrap'
import Snackbar from "../utils/snackbar";
import { getConnectedStakingContract, getConnectedERC721Contract } from '../utils/Contracts/ContractConnections';


const SnackbarType = {
  success: "success",
  fail: "fail",
};

export const PartnerMintButton = (props) => {
    const [mintAmount, setMintAmount] = useState(1);
    const [accounts, setAccounts] = useState([]);
    const [connectedContract, setConnectedContract] = useState(null);
    const [isOwner, setIsOwner] = useState(false);
    const [adress, setAdress] = useState("");
    //const [totalSupplyCount, SetTotalSupplyCount] = useState(null);
    //const [totalMintableCount, SetTotalMintableCount] = useState(null);

    const [loading, setLoading] = useState("none");
    const [loading2, setLoading2] = useState("none");
    const [loadingText, setLoadingText] = useState("Load Membership Pass");
    const [loadingText2, setLoadingText2] = useState("Link ClubGG Username");
    const [presaleOpen, SetPresaleOpen] = useState(false);
    const [saleOpen, SetPublicSaleOpen] = useState(false);
    const [snackBarType, SetSnackBarType] = useState(SnackbarType.fail)
    const [snackBarMessage, SetSnackBarMessage] = useState("")

    const { MerkleTree } = require('merkletreejs')
    const keccak256 = require('keccak256')
    const { toChecksumAddress } = require('ethereum-checksum-address')

    const snackbarRef = useRef(null);


  function updateCounter(amount){
      if(mintAmount > 0 && amount == 1){
        if((mintAmount + amount) <= 10){
          setMintAmount(mintAmount + 1);
        }else{
          setMintAmount(10)
        }
      }
      if(mintAmount > 0 && amount == -1){
        if((mintAmount + amount) >= 1){
          setMintAmount(mintAmount - 1)
        }
      }
  }

  useEffect(() => {
    if(!connectedContract) return;
    isSaleOpen();
    //getTotalSupplyCount()
  }, []);


  const mintNFT = async () => {
    try{
      const connectedContract = await getConnectedERC721Contract();

      if(!connectedContract) return;
        var msgValue = (mintAmount * 55550000000000000).toString();
        const price = await connectedContract.estimateGas.mint(mintAmount, {
          value: msgValue})
          var price1 = parseInt(price.toHexString(16), 16);
        const mintTxn = await connectedContract.mint(mintAmount, {
          value: msgValue})
        await mintTxn.wait();
    } catch (err){
      SetSnackBarMessage("Minting Error")
      SetSnackBarType(SnackbarType.fail)
      snackbarRef.current.show()
      console.log(err);
    }
  }

  const mintFreeNFT = async () => {
    try{
      const connectedContract = await getConnectedERC721Contract();

      if(!connectedContract) return;
        const mintTxn = await connectedContract.mint(mintAmount)
        await mintTxn.wait();
    } catch (err){
      SetSnackBarMessage("Minting Error")
      SetSnackBarType(SnackbarType.fail)
      snackbarRef.current.show()
      console.log(err);
    }
  }

  const isSaleOpen = async () => {
    try{
      const connectedContract = await getConnectedERC721Contract();
      console.log(connectedContract);

      if(!connectedContract) return;
        const saleOpen = await connectedContract.mintOpen()
        SetPublicSaleOpen(true);
        if(saleOpen){
          mintFreeNFT();
          SetPublicSaleOpen(false);
        }else{
          SetSnackBarMessage("Sale Locked")
          SetSnackBarType(SnackbarType.fail)
          snackbarRef.current.show()
        }
    }catch(err){
      SetSnackBarMessage("Error Minting")
      SetSnackBarType(SnackbarType.fail)
      snackbarRef.current.show()
      console.log(err);
    }
  }


  async function handleMint() {
    if (window.ethereum) {
      const address = await window.ethereum.request({
        method: "eth_accounts",
      });

      await isSaleOpen();
    }
  }

  const [state, setState] = useState('idle');

  return(
    <div>
      {/* {accounts.length && ( */}
      {/* <div className="columns-holder">
          <div className="column">
            <div className="box"> */}
            <div className="button-holder">
            {/* <Button className='membershipButton' variant="grey" onClick={setNameButton}>
                <Spinner
                as="span"
                variant="white"
                size="sm"
                role="status"
                aria-hidden="true"
                animation={loading2}
                />
                {loadingText2}
            </Button> */}
                <ReactiveButton
                    buttonState={state}
                    onClick={handleMint}
                    color={'red'}
                    idleText={<h2>FREE MINT</h2>}
                    loadingText={<h2>Minting...</h2>}
                    successText={<h2>Minting...</h2>}
                    errorText={'Error'}
                    type={'button'}
                    className={'class1 class2'}
                    style={{ borderRadius: '10px' }}
                    outline={false}
                    shadow={false}
                    rounded={true}
                    size={'large'}
                    block={true}
                    messageDuration={5000}
                    disabled={false}
                    buttonRef={null}
                    width={300}
                    height={60}
                    animation={true}
                />
                <Snackbar
                  ref={snackbarRef}
                  message={snackBarMessage}
                  type={snackBarType}
                />
            </div>
          </div>
  )

}