import './prizes.css'
import {ethers} from 'ethers';
import AnimatedPage from '../../utils/AnimatedPage';
// import pokerTable from "../../images/pokerTable2.jpeg"
import Snackbar from "./../../utils/snackbar";
import React, { useState, useEffect, useRef } from 'react'; 
import { Button, Spinner } from 'react-bootstrap'
import ReactiveButton from 'reactive-button';
// import Payout from '../../images/prizePool3.png'
// import PayoutDesktop from '../../images/prizePool3.png'

import Footer from "../Footer/Footer"
// import banner from "../../images/Desktop_Website_Banner.png"
// import banner2 from "../../images/Mobile_Website_Banner.png"
import contractJson from "../../../template.json";
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const contractAddress = "";
const SnackbarType = {
    success: "success",
    fail: "fail",
};
const banner = "https://venftian.s3.eu-west-2.amazonaws.com/Desktop_Website_Banner.png"
const banner2 = "https://venftian.s3.eu-west-2.amazonaws.com/Mobile_Website_Banner.png"
const Payout = 'https://venftian.s3.eu-west-2.amazonaws.com/Prize_Page.jpg'
const PayoutDesktop = 'https://venftian.s3.eu-west-2.amazonaws.com/Prize_Page.jpg'

function Prizes(){
    const [snackBarType, SetSnackBarType] = useState(SnackbarType.fail)
    const [snackBarMessage, SetSnackBarMessage] = useState("")
    const [loading, setLoading] = useState("none");
    const [loadingText, setLoadingText] = useState("Check Winnings");
    const [prizeWinnings, setPrizeWinnings] = useState(0)
    const [connectedContract, setConnectedContract] = useState(null);
    const pokerTable = "https://venftian.s3.eu-west-2.amazonaws.com/bannerNoPasses.png"
    const snackbarRef = useRef(null);

    function buttonLoad(){
        if(loading == "none"){
            setLoading("border")
            setLoadingText(" Loading")
        }
    }

    async function loadPrizes(){
        setLoading("border")
        setLoadingText(" Loading")
        setTimeout(() => {
            SetSnackBarMessage("No Membership Found")
            SetSnackBarType(SnackbarType.fail)
            snackbarRef.current.show() 
            setLoading("none")
            setLoadingText("Check Winnings")
         }, 2900);    
    }

    const getConnectedContract = async () => {
        const {ethereum} = window;
        if(!ethereum) return;
    
        const provider = new ethers.providers.Web3Provider(
          ethereum
        );
    
        const signer = provider.getSigner();
    
        const connectedContract =
          new ethers.Contract(
            contractAddress,
            contractJson.abi,
            signer
          )
        setConnectedContract(connectedContract)
    }

    async function claimPrizes(){
        // var passNumber = "Not Found";
        // try{
        //   if(!connectedContract) return;
        //   passNumber = await connectedContract.returnCounter()
        //   setLoading("None")
        //   setLoadingText("Loaded")
        // }catch(err){
            SetSnackBarMessage("No Prizes To Claim")
            SetSnackBarType(SnackbarType.fail)
            snackbarRef.current.show()     
            //console.log(err);
        //}
    }

    return(
    <div className='container2'>
        <div className='mint-background'>
                <AnimatedPage>
                <div className='image-header2'>
                    <img className='image-header-pc' src={banner}/>
                    <img className='image-header-mobile' src={banner2}/>
                </div>
                <div className='mint-background-inner'>
                <div className='page-contents'>
                        <Snackbar
                            ref={snackbarRef}
                            message={snackBarMessage}
                            type={snackBarType}
                        />
                        <div className='title'> Prizes
                        </div>
                        <div className='page-sections-prize'>

                            <div data-aos="fade-left"className='right-prize'>
                                <div  className='claim-prize-text'>
                                    <h1>How To Claim Prizes</h1>
                                    <p>
                                        At the end of each tournament week, winners will be audited and uploaded directly
                                        to the smart contract along with their associated prizes, providing our holders with full transparency.
                                    </p>
                                    <p>
                                        Simply connect your wallet and click 'Withdraw Prize' below to collect your winnings.
                                    </p>
                                    <div className='prizes-search'>
                                    <Button className='winningsButton' variant='red' onClick={loadPrizes}>
                                        <Spinner
                                        as="span"
                                        variant="white"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation={loading}
                                        />
                                        <span className='buttonText'>{loadingText}</span>
                                    </Button>
                                        <div className='prizeWinnings'>
                                            {prizeWinnings}
                                        </div>
                                        <div className='clubggButton'>
                                        </div>
                                    </div>
                                    <ReactiveButton
                                        buttonState={'idle'}
                                        onClick={claimPrizes}
                                        color={'red'}
                                        idleText={<h2>Withdraw Prize</h2>}
                                        errorText={'Error'}
                                        type={'button'}
                                        className={'class1 class2'}
                                        style={{ borderRadius: '10px' }}
                                        outline={false}
                                        shadow={false}
                                        rounded={true}
                                        size={'large'}
                                        block={true}
                                        messageDuration={5000}
                                        disabled={false}
                                        buttonRef={null}
                                        width={350}
                                        height={50}
                                        animation={true}
                                    /></div>
                            </div>

                            <div data-aos="fade-right" className='left-prize'>
                                {/* <h1>Prize Pools</h1> */}
                                <div className='prize-container'>
                                    {/* <h1>Prize Pools</h1> */}
                                    <div className='img-container-payouts'>
                                        <img className="payout-mobile" src={Payout}/>
                                        <img className="payout-desktop" src={PayoutDesktop}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AnimatedPage>
        </div>
        <Footer/>
    </div>
    )
}

export default Prizes;