import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { MenuList } from "./MenuList";
import "./Navbar.css";
//import "../../Minting/Minter"
import Minter from "../../minter/Minter";
import Logo from './../../images/LogoMainNoBk.png'


const Navbar = () => {
  const [clicked, setClicked] = useState(false);
  const [style, setStyle] = useState("")

  const menuList = MenuList.map(({ url, title }, index) => {

    return (
      <li key={index}>
        <NavLink exact to={url} activeClassName="active"> 
        {/* onClick={changeStyle}> */}
          {title}
        </NavLink>
      </li>
    );
  });

  const walletButton = () => {
    return(
      <a>
      <Minter/>
      </a>
    );
  }

  const handleClick = () => {
    setClicked(!clicked);
  };


  return (
    <div>
      <nav className="nav">        
        <div className="logo">
          <img src={Logo}/>
        </div>
        <div className="menu-icon" onClick={handleClick}>
          <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
        <ul>
        {/* <ul className="menu-list"> */}
          {/* {menuList} */}
          <ul className={clicked ? "menu-list" : "menu-list close"}>{menuList} 
          <Minter/>      
        </ul>
        
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
