import React, { useState, useEffect, useRef } from 'react'; 
// import MintButton from "../Minting/MintButton"
// import Minter from "../Minting/Minter";
import "./../home.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram, faTwitterSquare } from "@fortawesome/free-brands-svg-icons"
import CasinoMov from "./../../../videos/casino-loop.mp4"
import logo from './../../../images/MobileLanding.png'
// import logo from "./../../images/LogoMainNoBk2.png"
import ReactiveButton from 'reactive-button';
import {PartnerMintButton} from '../../../minter/PartnerMintButtons'
import Footer from "../../Footer/Footer";
import Video from "./../../../utils/Videos/Video"
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

export default function Partner1() {
  const [background, SetBackground] = useState("https://venftian.s3.eu-west-2.amazonaws.com/VeNFTiaN_Promo.mp4")
  const chipsBackground = "https://venftian.s3.eu-west-2.amazonaws.com/chips.mp4"
  const MintGif = "https://venftian.s3.eu-west-2.amazonaws.com/mintGif.mp4"
  const promoVideo = "https://venftian.s3.eu-west-2.amazonaws.com/VeNFTiaN_Promo_Mobile.mp4"
  const backgroundMobile = "https://venftian.s3.eu-west-2.amazonaws.com/Mobile_web_landing.png";

  function callMint(){
      console.log("test")
  }

    // useEffect(() => {
    //     console.log(window.screen.availWidth)
    //     if(window.screen.availWidth < 850){
    //         console.log("working?")
    //         SetBackground("https://venftian.s3.eu-west-2.amazonaws.com/casino-loop.mp4")
    //         console.log(background)
    //     }
    // }, []);

  function redirectClubgg(){
      window.open(
          'https://www.clubgg.net/',
          '_blank'
        );
  }

  return (
    <div className="home-container">
        <div className="background-home">
            {/* <video
            autoPlay
            loop
            muted>
                <source src={background} type="video/mp4"/>
            </video> */}
            <Video video={background} className="background-pc"/>
            {/* <Video video={backgroundMobile} className="background-mobile"/> */}
            <div className="logo-container2">
                {/*<div className="logo-container">
                <img src={logo}/>
                 <p>Creating A Unique Gambling Experience For A New Generation</p>
                <p>1 NFT - 32 Free Tounaments - Huge Prize Pools</p>
                </div> */}
                <img src={backgroundMobile}/>
            </div>
        </div>
            <div className='mint-background-inner'>           
                <div className='page-contents'>
                    <div className='title'> How It Works
                    </div>
                    <div className='page-sections'>
                        <div className='promo-container'>
                                <Video video={promoVideo}/>
                        </div>
                        <div className='left'>
                            <div data-aos="fade-right" className='text-holder'>
                                <h1>Who are we?</h1>
                                <p>
                                    The<em> VeNFTian Poker Club</em> seeks to create a 
                                    unique poker experience, bringing together both traditional players and 
                                    Web3 communities. 
                                    Utilizing the security and transparency of the latest smart contracts, 
                                    we have revolutionized the registration and reward system of tournament poker.
                                </p>
                                <p>
                                    Our tournaments currently take place on <em>ClubGG</em>, a third party poker application. You can download 
                                    this by following the link below. 
                                </p>
                            </div>
                            <div data-aos="fade-right" className='clubggButton'>
                                <ReactiveButton
                                    buttonState={'idle'}
                                    onClick={redirectClubgg}
                                    color={'red'}
                                    idleText={<h2>Download ClubGG</h2>}
                                    errorText={'Error'}
                                    type={'button'}
                                    className={'class1 class2'}
                                    style={{ borderRadius: '10px' }}
                                    outline={false}
                                    shadow={false}
                                    rounded={true}
                                    size={'large'}
                                    block={true}
                                    messageDuration={5000}
                                    disabled={false}
                                    buttonRef={null}
                                    width={400}
                                    height={50}
                                    animation={true}
                                />
                            </div>
                            <div data-aos="fade-right" className='text-holder'>
                                <h1>FREE MINT - Pre Season</h1>
                                <p>
                                    Here you can mint your <em>Free Roll Tournament Pass</em> entitling you to a seat at <em>two tournaments </em> 
                                    with a variety of prizes to get you warmed up for <em>Season One</em>.
                                </p>
                            </div>

                            <div data-aos="fade-right" className='text-holder'>
                                <h1>VIP Membership - Season 1 (Coming Soon)</h1>
                                <p>
                                    Here you can mint your VIP Membership Pass for only $200 giving you access to the <em> VeNFTian Poker Club</em>. 
                                    Our club members have <em>free access to 32 tournaments</em>, 
                                    with over <em>$500,000 </em>worth of prizes, spread over 8 weeks of exciting games! 
                                </p>
                            </div>
                        </div>
                        <div data-aos="fade-left" className='right'>
                            <div className='img-container'>
                                <div className='img-container-overlay'> 
                                    <h1 className='freeMint'>FREE MINT</h1>
                                </div>
                                <Video video={MintGif}/>
                            </div>
                            <PartnerMintButton/>
                        </div>
                    </div>

                </div>           
            </div> 
            <Footer/> 
      </div>
  );
}
