import React, { useState, useEffect } from 'react'; 
import MintButton from '../../minter/MintButton'
import './mint.css'
import AnimatedPage from '../../utils/AnimatedPage';
// import Chips from "./../../videos/chips.mp4"
// import MintGif from "./../../videos/mintGif.mp4"
import ReactiveButton from 'reactive-button';
import { Button } from 'react-bootstrap'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Mint = () =>{
    const [state, setState] = useState('idle');
    const [over, setOver] = useState(false);
    const chipsBackground = "https://venftian.s3.eu-west-2.amazonaws.com/chips.mp4"
    const MintGif = "https://venftian.s3.eu-west-2.amazonaws.com/mintGif.mp4"

    function callMint(){
        console.log("test")
    }

    function redirectClubgg(){
        window.open(
            'https://www.clubgg.net/',
            '_blank'
          );
    }


    return(
    
        <div className='container2'>
            <div className='mint-background'>   
            <AnimatedPage>
                <div className='image-header'>
                    <video
                        autoPlay
                        loop
                        muted>
                            <source src={chipsBackground} type="video/mp4"/>
                    </video>
                    </div>
                <div className='mint-background-inner'>           
                    <div className='page-contents'>
                        <div className='title'> How It Works
                        </div>
                        <div className='page-sections'>
                            <div className='left'>
                                <div className='text-holder'>
                                    <h1>What is a VeNFTian Membership?</h1>
                                    <p>
                                        some explanation some explanation some explanation some explanation 
                                        some explanation some explanation some explanation some explanation 
                                        some explanation some explanation some explanation some explanation 
                                        some explanation some explanation some explanation some explanation 
                                    </p>
                                </div>
                                <div className='clubggButton'>
                                    <ReactiveButton
                                        buttonState={'idle'}
                                        onClick={redirectClubgg}
                                        color={'red'}
                                        idleText={<h2>Download Club.GG</h2>}
                                        errorText={'Error'}
                                        type={'button'}
                                        className={'class1 class2'}
                                        style={{ borderRadius: '10px' }}
                                        outline={false}
                                        shadow={false}
                                        rounded={true}
                                        size={'large'}
                                        block={true}
                                        messageDuration={5000}
                                        disabled={false}
                                        buttonRef={null}
                                        width={400}
                                        height={50}
                                        animation={true}
                                    />
                                </div>
                                <div className='text-holder'>
                                    <h1>Prize Pool</h1>
                                    <p>
                                        some explanation some explanation some explanation some explanation 
                                        some explanation some explanation some explanation some explanation 
                                        some explanation some explanation some explanation some explanation 
                                        some explanation some explanation some explanation some explanation 
                                    </p>
                                </div>
                            </div>
                            <div className='right'>
                                <div className='img-container'>
                                    {/* <img
                                        onClick={callMint}
                                        src={over ? cardClicked : cardUnclicked}
                                        onMouseOver={() => setOver(true)}
                                        onMouseOut={() => setOver(false)}
                                    /> */}
                                    <video
                                        autoPlay
                                        loop
                                        muted>
                                            <source src={MintGif} type="video/mp4"/>
                                    </video>
                                </div>
                                <MintButton/>
                            </div>
                        </div>

                    </div>           
                </div>
                
            </AnimatedPage>
            </div> 
        </div>

    )
}

export default Mint;
