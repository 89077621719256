import React from 'react';
import { MDBTable, MDBTableBody, MDBTableHead, MDBDataTable  } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

const DatatablePage = () => {
  const data = {
    columns: [
      {
        label: 'Tournament Type',
        field: 'tournamentType',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Start Time / Date',
        field: 'date',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Prize Pool Value',
        field: 'prizePool',
        sort: 'asc',
        width: 100
      }
    ],
    rows: [
      {
        tournamentType: 'Win Your Mint',
        date: 'TBD',
        prizePool: '$25,000' 
      },
      {
        tournamentType: 'Community Event',
        date: 'TBD',
        prizePool: 'TBA' 
      }, 
      {
        tournamentType: 'Warm-Up Event',
        date: 'TBD',
        prizePool: '$17,500' 
      },
      {
        tournamentType: 'Main Event',
        date: 'TBD',
        prizePool: '$18,175' 
      },
      {
        tournamentType: 'Win Your Mint',
        date: 'TBD',
        prizePool: '$25,000' 
      },
      {
        tournamentType: 'Community Event',
        date: 'TBD',
        prizePool: 'TBA' 
      }, 
      {
        tournamentType: 'Warm-Up Event',
        date: 'TBD',
        prizePool: '$17,500' 
      },
      {
        tournamentType: 'Main Event',
        date: 'TBD',
        prizePool: '$18,175' 
      },  
      {
        tournamentType: 'Win Your Mint',
        date: 'TBD',
        prizePool: '$25,000' 
      },
      {
        tournamentType: 'Community Event',
        date: 'TBD',
        prizePool: 'TBA' 
      }, 
      {
        tournamentType: 'Warm-Up Event',
        date: 'TBD',
        prizePool: '$17,500' 
      },
      {
        tournamentType: 'Main Event',
        date: 'TBD',
        prizePool: '$18,175' 
      },  
      {
        tournamentType: 'Win Your Mint',
        date: 'TBD',
        prizePool: '$25,000' 
      },
      {
        tournamentType: 'Community Event',
        date: 'TBD',
        prizePool: 'TBA' 
      }, 
      {
        tournamentType: 'Warm-Up Event',
        date: 'TBD',
        prizePool: '$17,500' 
      },
      {
        tournamentType: 'Main Event',
        date: 'TBD',
        prizePool: '$18,175' 
      },  
      {
        tournamentType: 'Win Your Mint',
        date: 'TBD',
        prizePool: '$25,000' 
      },
      {
        tournamentType: 'Community Event',
        date: 'TBD',
        prizePool: 'TBA' 
      }, 
      {
        tournamentType: 'Warm-Up Event',
        date: 'TBD',
        prizePool: '$17,500' 
      },
      {
        tournamentType: 'Main Event',
        date: 'TBD',
        prizePool: '$18,175' 
      },  
      {
        tournamentType: 'Win Your Mint',
        date: 'TBD',
        prizePool: '$25,000' 
      },
      {
        tournamentType: 'Community Event',
        date: 'TBD',
        prizePool: 'TBA' 
      }, 
      {
        tournamentType: 'Warm-Up Event',
        date: 'TBD',
        prizePool: '$17,500' 
      },
      {
        tournamentType: 'Main Event',
        date: 'TBD',
        prizePool: '$18,175' 
      },  
      {
        tournamentType: 'Win Your Mint',
        date: 'TBD',
        prizePool: '$25,000' 
      },
      {
        tournamentType: 'Community Event',
        date: 'TBD',
        prizePool: 'TBA' 
      }, 
      {
        tournamentType: 'Warm-Up Event',
        date: 'TBD',
        prizePool: '$17,500' 
      },
      {
        tournamentType: 'Main Event',
        date: 'TBD',
        prizePool: '$18,175' 
      },  
      {
        tournamentType: 'Win Your Mint',
        date: 'TBD',
        prizePool: '$25,000' 
      },
      {
        tournamentType: 'Community Event',
        date: 'TBD',
        prizePool: 'TBA' 
      }, 
      {
        tournamentType: 'Warm-Up Event',
        date: 'TBD',
        prizePool: '$17,500' 
      },
      {
        tournamentType: 'Main Event',
        date: 'TBD',
        prizePool: '$18,175' 
      }         
    ]
  };

  return (
    <MDBDataTable
      scrollY
      maxHeight="600px"
      // striped
      bordered
      small
      data={data}
      tbodyColor='black'
      theadColor='black'
      tbodyTextWhite
      theadTextWhite
    />
  );
}

export default DatatablePage;
