import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram, faTwitterSquare, faDiscord, faTwitch} from "@fortawesome/free-brands-svg-icons"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

const AccordionItem = ({
  showDescription,
  ariaExpanded,
  fontWeightBold,
  item,
  index,
  onClick,
}) => (
  <div className="faq__question" key={item.question}>
    <dt>
      <button
        aria-expanded={ariaExpanded}
        aria-controls={`faq${index + 1}_desc`}
        data-qa="faq__question-button"
        className={`faq__question-button ${fontWeightBold}`}
        onClick={onClick}
      >
        {item.question}      
          <FontAwesomeIcon icon={faAngleDown} />
        </button>
    </dt>
    <dd>
      <p
        id={`faq${index + 1}_desc`}
        data-qa="faq__desc"
        className={`faq__desc ${showDescription}`}
      >
        {item.answer} <br/> {item.answer2} <br/>
        {item.answer3} <br/> {item.answer4} <br/> {item.answer5} <br/> {item.answer6}
      </p>
    </dd>
  </div>
);

export default AccordionItem;