import {ethers, BigNumber} from "ethers";
import React, {useState } from "react";
import PixelStakingAbi from "./PixelStakingAbi.json";
import VeNFTianFreeMintERC721Abi from "./VeNFTianFreeMintERC721Abi.json";

const VeNFTianFreeMintERC721 = "0xb51CA01e3C890508b2EdE446e6cd5B5fC8849e5B";
const PixelStaking = "0x3370A7b91Bf81f349452905c3F8f18a477983F42";


export const getConnectedStakingContract = async () => {
    const {ethereum} = window;
    if(!ethereum) return;

    const provider = new ethers.providers.Web3Provider(
      ethereum
    );

    const signer = provider.getSigner();

    const connectedContract =
      new ethers.Contract(
        PixelStaking,
        PixelStakingAbi.abi,
        signer
    )

    return connectedContract
}

export const getConnectedERC721Contract = async () => {
    const {ethereum} = window;
    if(!ethereum) return;

    const provider = new ethers.providers.Web3Provider(
      ethereum
    );

    const signer = provider.getSigner();

    const connectedContract =
      new ethers.Contract(
        VeNFTianFreeMintERC721,
        VeNFTianFreeMintERC721Abi.abi,
        signer
    )
    return connectedContract
}