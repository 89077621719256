export const MenuList = [
  {
    title: "Home",
    url: "/",
  },
  // {
  //   title: "Membership",
  //   url: "/Mint",
  // },
  {
    title: "Poker",
    url: "/Poker",
  },
  {
    title: "Prizes",
    url: "/Prizes",
  },
  {
    title: "FAQ",
    url: "/FAQ",
  }
];
